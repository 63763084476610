import { useEffect, useState } from "react";
import styles from "../styles/style.module.css";
import { LOCAL_CONSTANTS } from "../../../lib/constants";
import moment from "moment";
import { OrderTypes } from "../../../interfaces";
import { useNavigate, useParams } from "react-router-dom";
import UpsellForm from "../../../ui/modals/upsellForm";
import { manuallySentMail } from "../api";
import { toast } from "react-toastify";

interface UserListProp {
  loading: boolean;
  orders: any;
  setRefreshData: any;
  refreshData: any;
  activeTab: any;
}

const VFSPhilipCouponList = ({
  loading,
  orders,
  setRefreshData,
  refreshData,
  activeTab,
}: UserListProp) => {
  const isFree = orders?.list[0]?.isFree;
  const [show, setShow] = useState<boolean>(false);
  const [orderId, setOrderId] = useState<string>("");
  const { partnerName, partnerWebsiteId } = useParams();

  const navigate = useNavigate();

  const roles = window.localStorage.getItem("admin_roles");
  const routAccess =
    (roles !== null && LOCAL_CONSTANTS.ROLES.NIYO_USER === Number(roles)) ||
    (roles !== null && LOCAL_CONSTANTS.ROLES.VFS_USER === Number(roles));

  return (
    <>
      <div className={styles.total_count}>
        <label>Total Coupons</label>
        <span className={styles.order_count}>{orders?.count}</span>
      </div>
      <div className={styles.scrollTable}>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Name/Code</th>
              <th>Description</th>
              <th>Data</th>
              {activeTab === 8 && (
                <>
                  <th>User Name</th>
                  <th>User Email</th>
                  <th>User Phone no</th>
                  <th>Plan Name</th>
                  <th>Order No</th>
                  <th>Order Price</th>
                  <th>Upsell Amount</th>
                  <th>Upsell Currency</th>
                  <th>Commission</th>
                </>
              )}
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan={7} align="center" className={styles.loadingText}>
                  {LOCAL_CONSTANTS.loading}
                </td>
              </tr>
            )}
            {!loading && orders.list.length === 0 && (
              <tr>
                <td colSpan={7} align="center">
                  Coupon not found
                </td>
              </tr>
            )}
            {!loading &&
              orders.list.map((item: any, index: number) => (
                <tr key={item._id}>
                  <td>{index + 1}</td>
                  <td>{`${item.code || LOCAL_CONSTANTS.na}`}</td>
                  <td>{`${item.description || LOCAL_CONSTANTS.na}`}</td>
                  <td>{`${
                    item.dataAmount / 1000 + " GB" || LOCAL_CONSTANTS.na
                  }`}</td>
                  {activeTab === 8 && (
                    <>
                      <td>{`${item.name || LOCAL_CONSTANTS.na}`}</td>
                      <td>{`${item.email || LOCAL_CONSTANTS.na}`}</td>
                      <td>{`${item.mobile || LOCAL_CONSTANTS.na}`}</td>
                      <td>{`${item.planName || LOCAL_CONSTANTS.na}`}</td>
                      <td>{`${item.orderNumber || LOCAL_CONSTANTS.na}`}</td>
                      <td>{`${item.orderPrice || 0}`}</td>
                      <td>{`${item.upsellAmount || 0}`}</td>
                      <td>{`${
                        item.upsellAmountCurrency || LOCAL_CONSTANTS.na
                      }`}</td>
                      <td>{`${item.commission || 0}`}</td>
                    </>
                  )}
                  <td>{`${
                    item.status == 1
                      ? "Active"
                      : item.status == 2
                      ? "Used"
                      : "Expire"
                  }`}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default VFSPhilipCouponList;
