export const LOCAL_CONSTANTS = {
  accountStatus: "Account Status",
  areYouSure: "Are you sure?",
  areYouSureRestrictUserAccess: "Are you sure you want to restrict access for this user?",
  removePromo: "Are you sure you want to remove this promo",
  removeCoupon: "Are you sure you want to remove this coupon",
  removeBlog: "Are you sure you want to remove this blog",
  removeFAQ: "Are you sure you want to remove this faq",
  pendingBlog: "Are you sure you want to approve/reject this blog",
  approvedBlog: "Are you sure you want to reject this blog",
  rejectBlog: "Are you sure you want to approve this blog",
  approveComment: "Are you sure you want to approve this comment",
  noPromoFound: "No promo found",
  noCouponFound: "No coupon found",
  noBlogFound: "No blog found",
  ascKey: 1,
  dateFormat: "D MMM YYYY",
  descKey: -1,
  errorOnUpdatingUser: "There is some error updating the user",
  limitCount: 10,
  loading: "Loading",
  na: "N/A",
  no: "No",
  paymentOrderStatus: [
    {
      value: 1,
      label: "Pending",
    },
    {
      value: 2,
      label: "Success",
    },
    {
      value: 3,
      label: "Failed",
    },
  ],
  paymentStatus: [
    {
      value: 1,
      label: "Pending",
    },
    {
      value: 2,
      label: "Success",
    },
    {
      value: 3,
      label: "Failed",
    },
    {
      value: 4,
      label: "Processing",
    },
  ],
  paymentsTableHeaders: [
    { value: "user.fullName", label: "Name" },
    { value: "user.email", label: "Email" },
    { value: "bundle.name", label: "Bundle Name" },
    { value: "date", label: "Date" },
    { value: "orderStatus", label: "Order Status" },
    { value: "paymentStatus", label: "Payment Status" },
    { value: "currency", label: "Currency" },
    { value: "price", label: "Price" },
    { value: "qty", label: "Quantity" },
    { value: "action", label: "Action" },
  ],
  updatingUser: "Updating User",
  userBlockedSuccessfully: "User Blocked successfully",
  usersTableHeaders: [
    { value: "fullName", label: "Name" },
    { value: "phoneNumber", label: "Phone Number" },
    { value: "email", label: "Email" },
    { value: "isEmailVerified", label: "Email Verified" },
    { value: "isPhoneNoVerified", label: "Mobile No Verified" },

    { value: "isBlocked", label: "Blocked/Unblocked" },
    { value: "actions", label: "Actions" },
  ],
  userUnblockedSuccessfully: "User unblocked successfully",
  yes: "Yes",
  promosTableHeaders: [
    { value: "promoCode", label: "Promo Code" },
    { value: "title", label: "Title" },
    { label: "Promo type" },
    { value: "discount", label: "Discount" },
    { value: "startDate", label: "Start Date" },
    { value: "endDate", label: "Expiry Date" },
    { label: "Actions" },
  ],
  couponTableHeaders: [
    { value: "couponCode", label: "Coupon Code" },
    { value: "title", label: "Title" },
    { label: "Coupon type" },
    { value: "discount", label: "Discount" },
    { value: "startDate", label: "Start Date" },
    { value: "endDate", label: "Expiry Date" },
    { label: "Actions" },
  ],
  blockedAccounts: [
    { value: null, label: "Select Account status" },
    { value: true, label: "blocked" },
    { value: false, label: "Unblocked" },
  ],
  blogTypes: [
    { value: null, label: "Select Blog Type" },
    { value: 1, label: "Created by Admin" },
    { value: 2, label: "Created by Users" },
  ],
  blogStatus: [
    { value: null, label: "Select Blog Status" },
    { value: 1, label: "Pending" },
    { value: 2, label: "Approved" },
    { value: 3, label: "Rejected" },
  ],
  phoneNoVerified: [
    { value: null, label: "Select Phone No Verification Status" },
    { value: true, label: "Verified" },
    { value: false, label: "Unverified" },
  ],
  emailVerified: [
    { value: null, label: "Select Email Verification Status" },
    { value: true, label: "Verified" },
    { value: false, label: "Unverified" },
  ],
  loyalityTableHeaders: [
    { value: "fullName", label: "User Name" },
    { value: "priceBeforeLoyality", label: "Actual Price" },
    { value: "priceAfterLoyality", label: "New Price" },
    { value: "quantity", label: "Quantity" },
    { value: "loyalityPoint", label: "Loyality Point" },
    { value: "createdAt", label: "Purchased At" },
  ],
  blogsTableHeaders: [
    { label: "Image" },
    { label: "Title" },
    { value: "status", label: "Status" },
    { value: "rating", label: "Rating" },
    { value: "userFullName", label: "Created By" },
    { value: "createdAt", label: "Created At" },
    { label: "Actions" },
  ],
  faqTableHeaders: [
    { label: "Question" },
    { label: "CreatedAt" },
    { label: "Actions" },
  ],
  countryPlansHeaders: [
    { label: "Plan name" },
    // { label: "Country" },
    // { label: "Vendor" },
    // { label: "Description" },
    // { label: "Product Code" },
    { label: "Validity" },
    { label: "Data" },
    { label: "Network" },
    { label: "Plan Amount" },
    { label: "Retail Amount" },
    { label: "Offer Amount" },
    { label: "Buy Amount" },
    { label: "Margin Price" },
    { label: "Margin %" },
    { label: "Total Sales" },
    { label: "Enable / Disable" },
    { label: "Document Required ?" }
  ],
  PARTNERS: {
    COMMBITZ: "commbitz",
    NIYO: "niyo",
    VFS: "vfs",
    GLOBALTIX: "globaltix",
    VFSPHILLI: "vfs-phillip"
  },
  ROLES :{
    COMMBITZ_USER : 2,
    NIYO_USER : 4,
    VFS_USER : 5,
    GLOBALTIX:7,
    VFS_PHILIP_USER: 8
  }
};
